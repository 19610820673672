import React, { HTMLProps, cloneElement } from 'react';
import { PlatformSectionPros, ProSectionContainer } from './ProSectionContainer';
import { cva } from 'class-variance-authority';
import { cn } from '../../utils/common';
import { Grid } from 'antd';


type MediaInfoCardProps = {

   /* enable to change the sequence of container */
   inverse?: boolean

   image?: HTMLProps<HTMLImageElement>

   rootClassName?: string

   node?: React.ReactElement
   nodeClassName?: string
   children?: React.ReactElement

   isHero?: boolean

} & PlatformSectionPros

const innerCls = cva(
   'flex items-center lg:items-center justify-between gap-10',
   {
      variants: {
         inverse: {
            true: 'flex-col-reverse lg:flex-row-reverse',
            false: 'flex-col lg:flex-row'
         },
      }
   }
)


const rootCls = cva(
   'py-12 ',
   {
      variants: {
         dark: {
            true: 'primary-gradient',
            false: ''
         }
      }
   }
)

const nodeCls = cva(
   cn(''),
   {
      variants: {
         dark: {
            true: 'text-white [&>*]:text-white',
            false: ''
         }
      }
   }
)

const MediaCls = cva(
   cn(
      'min-max-w-[350px] max-w-[350px] lg:min-w-[400px] lg:max-w-[400px] xl:min-w-[500px] xl:max-w-[500px] lg:ml-auto'
   ),
   {
      variants: {
         inverse: {
            true: 'lg:mr-auto',
            false: 'lg:ml-auto'
         }
      }
   }
)

const { useBreakpoint } = Grid;

export const MediaInfoCard = React.forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement> & MediaInfoCardProps>((props, ref) => {
   

   const {
      title,
      description,
      eyebrow,
      dark = false,
      inverse = false,
      align = 'start',
      rootClassName,
      className,
      extras,
      image,
      node,
      children,
      isHero = false,
      nodeClassName,

      ...rest
   } = props;


   return (
      <div
         ref={ref}
         className={rootCls({ className: rootClassName, dark })}
         data-aos="zoom-in"
         {...rest}
      >
         <div className={innerCls({ className, inverse })}>
            {children ? children : (
               <ProSectionContainer
                  title={title}
                  extras={extras}
                  description={description}
                  className='!py-0'
                  descriptionProps={{ className: '!mb-0 !text-[inherit] !max-w-[100%]' }}
                  dark={dark}
                  eyebrow={eyebrow}
                  align={align}
                  sectionContainerClassName={'pt-0'}
                  isHero={isHero}
               />
            )}
            {image && (
               <img
                  alt="no img"
                  src={''}
                  loading={'lazy'}
                  {...image}
                  className={MediaCls({ className: image?.className, inverse })}
               />
            )}
            {node && cloneElement(node, { className: nodeCls({ className: nodeClassName, dark }) })}
         </div>
      </div>
   )
})