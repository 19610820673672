import { SectionContainer, ProviderRespViewer, ProSectionContainer, MediaInfoCard, CommunitySection, ProCard, FancyContainer, AboutUsMainBanner, MeetTeam } from "../../../../docs/partials/index";
import * as React from 'react';
export default {
  SectionContainer,
  ProviderRespViewer,
  ProSectionContainer,
  MediaInfoCard,
  CommunitySection,
  ProCard,
  FancyContainer,
  AboutUsMainBanner,
  MeetTeam,
  React
};