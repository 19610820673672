import { cva } from 'class-variance-authority';
import React, { HTMLProps } from 'react';
import { ProSectionContainer } from '../ProSectionContainer';

const rootCls = cva(
   'gain-aboutUs-banner covered_aboutBanner',
   {
      variants: {

      }
   }
)

type GradientBannerProps = {
   title?: string
   description?: string
   centered?: boolean
   eyebrow?: string
   isHero?:boolean
}

export const AboutUsMainBanner = React.forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement> & GradientBannerProps>(
   (props, ref) => {

      const { className, title, description, eyebrow,isHero, centered = false, ...rest } = props;

      return (
         <div className={rootCls({ className })} ref={ref} {...rest}>
            <div className={`layout grid grid-cols-1 ${centered ? 'xl:grid-cols-1' : 'xl:grid-cols-2'}`}>
               <ProSectionContainer
                  align={'start'}
                  title={title}
                  description={description}
                  className='!bg-transparent !py-12'
                  descriptionProps={{ className: '!mb-0 !text-[inherit] !max-w-[100%]' }}
                  eyebrow={eyebrow}
                  isHero={isHero}
                  sectionContainerClassName={'pt-0'}
               />
               <div className=''></div>
            </div>
         </div>
      )
   })

  