import React, { useState } from "react";
import { Row, Col, Modal, Typography ,Grid} from "antd";

// Define the type for a team member
interface TeamMember {
    name: string;
    title: string;
    image: string;
    shortDescription: string;
    fullDescription: string;
}

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

// Sample data
const teamMembers: TeamMember[] = [
    {
        name: "Praveen Kumar ",
        title: "Co-Founder and CEO",
        image: "https://unzpublicpaas.blob.core.windows.net/imgs/equiwiz/praveen_1.png",
        shortDescription: "Praveen is a visionary leader driven by a mission to solve one of the biggest challenges for SaaS and Agentic AI systems – Integrations. Having built some of the most scaled SaaS over 2 decades, his expertise spans APIs, DevOps, cybersecurity and evolving landscape...",
        fullDescription: `Praveen is a visionary leader driven by a mission to solve one of the biggest challenges for SaaS and Agentic AI systems – Integrations. Having built some of the most scaled SaaS over 2 decades, his expertise spans APIs, DevOps, cybersecurity and evolving landscape of Agentic AI.   

        Before Unizo, Praveen led SaaS R&D at Black Duck Software (formerly Synopsys), where he spearheaded the launch of the Polaris AppSec Platform—a scalable security solution protecting hundreds of enterprises worldwide. A key part of his role was driving integrations across the Black Duck product portfolio, giving him firsthand insight into the complexity, cost, and friction SaaS companies face when building and managing integrations.   
        
        Having also held key roles at Google, Equinix, and Cisco, Praveen has seen how integrations can either unlock innovation or create bottlenecks — especially with the rise of AI-driven applications. At Unizo, he is committed to transforming integrations from an obstacle into a competitive advantage, enabling seamless, scalable connectivity for both SaaS and agentic AI platforms.`
    },
    {
        name: "Sudhanva Gnaneshwar",
        title: "Co-Founder and CTO",
        image: "https://unzpublicpaas.blob.core.windows.net/imgs/equiwiz/Sudhanva.png",
        shortDescription: "Sudhanva is a technology visionary with a passion for solving complex engineering challenges through scalable, distributed systems. With deep expertise in SaaS, APIs, and automation, he has dedicated his career to building high-performance, enterprise-grade systems...",
        fullDescription: `Sudhanva is a technology visionary with a passion for solving complex engineering challenges through scalable, distributed systems. With deep expertise in SaaS, APIs, and automation, he has dedicated his career to building high-performance, enterprise-grade systems designed for scale, security, and reliability.
        Before Unizo, Sudhanva led engineering teams at Equinix and Apple, where he built large-scale products leveraging cloud, APIs, and infrastructure to drive efficiency and performance. His experience designing and developing integration frameworks for enterprise SaaS solutions has given him firsthand insight into the technical and operational bottlenecks that hinder innovation, increase costs, and limit scalability.
        At Unizo, Sudhanva is on a mission to remove these barriers by building a robust, developer-centric AI powered integration platform that enables SaaS and AI-driven applications to connect effortlessly, securely, and at scale.`
    },
    {
        name: "Gunaseelan Kumar ",
        title: "Founding Engineer",
        image: "https://unzpublicpaas.blob.core.windows.net/imgs/equiwiz/guna.png",
        shortDescription: "Gunaseelan is focused on crafting intuitive and powerful user experiences for Unizo’s platform. Specializing in modern front-end technologies, he ensures that the Unizo interface is both developer-friendly and enterprise-ready. His expertise in UI/UX design...",
        fullDescription: `Gunaseelan is focused on crafting intuitive and powerful user experiences for Unizo’s platform. Specializing in modern front-end technologies, he ensures that the Unizo interface is both developer-friendly and enterprise-ready. 

        His expertise in UI/UX design enhances the accessibility of Unizo’s integration capabilities, providing SaaS businesses with seamless control over their integration strategies.`
    },
    {
        name: "Prasad Revanaki",
        title: "Founding Engineer",
        image: "https://unzpublicpaas.blob.core.windows.net/imgs/equiwiz/prasad.png",
        shortDescription: "Prasad leads Unizo’s DevOps and cloud infrastructure strategy, ensuring high availability, security, and performance at scale. With extensive experience in cloud-native architectures, API security, and CI/CD automation, he plays a critical role...",
        fullDescription: `Prasad leads Unizo’s DevOps and cloud infrastructure strategy, ensuring high availability, security, and performance at scale. With extensive experience in cloud-native architectures, API security, and CI/CD automation, he plays a critical role in optimizing Unizo’s enterprise platform. 

        His focus is on building resilient, scalable, and efficient cloud operations, allowing SaaS businesses to deploy and manage integrations with confidence and agility. `,
    },
    {
        name: "Rabiya Kousar",
        title: "Founding Engineer",
        image: "https://unzpublicpaas.blob.core.windows.net/imgs/equiwiz/Rabiya.png",
        shortDescription: "Rabiya is instrumental in driving Unizo’s product excellence and customer success. She specializes in deep analysis of provider APIs, designing tight integrations, and normalizing data to ensure seamless interoperability across platforms. She leads key initiatives to optimize data integration...",
        fullDescription: `Rabiya is instrumental in driving Unizo’s product excellence and customer success. She specializes in deep analysis of provider APIs, designing tight integrations, and normalizing data to ensure seamless interoperability across platforms. 

        She leads key initiatives to optimize data integration, enhance automation, and deliver high-performance. She ensures that Unizo delivers intuitive, enterprise-ready solutions that enhance operational efficiency and business agility.`
    },
    {
        name: "VijayaLakshmi Kota",
        title: "Founding Engineer",
        image: "https://unzpublicpaas.blob.core.windows.net/imgs/equiwiz/VJ.png",
        shortDescription: "VijayLaxmi is a key contributor to Unizo’s backend engineering and API development, designing robust and scalable SaaS architectures. She specializes in high-performance system optimization, ensuring that Unizo’s platform seamlessly integrates...",
        fullDescription: `VijayLaxmi is a key contributor to Unizo’s backend engineering and API development, designing robust and scalable SaaS architectures. She specializes in high-performance system optimization, ensuring that Unizo’s platform seamlessly integrates with enterprise applications. 

        With a passion for engineering excellence, she is committed to developing a best-in-class integration framework that empowers SaaS providers to scale their ecosystems effortlessly. `
    }
];

export const MeetTeam: React.FC = () => {
    const [visible, setVisible] = useState<boolean>(false);
    const [selectedMember, setSelectedMember] = useState<TeamMember | null>(null);
    const screens = useBreakpoint();

  // Set dynamic padding based on screen size
  const getPadding = () => {
    if (screens.xxl) return "13rem"; // Extra large screens
    if (screens.xl) return "15rem";   // Large desktops
    if (screens.lg) return "10rem";   // Laptops
    if (screens.md) return "2rem";   // Tablets
    return "1rem";                   // Mobile screens
  };

    const showModal = (member: TeamMember): void => {
        setSelectedMember(member);
        setVisible(true);
    };

    const handleClose = (): void => {
        setVisible(false);
        setSelectedMember(null);
    };

    return (
        <div style={{ textAlign: "center", padding: "40px"}} className="layout">
            {/* <h2>Meet the Team</h2> */}
            <div style={{ display: "flex", flexDirection: "column", gap: "1rem", paddingBottom: "2rem" }}>
                <Title level={2} style={{ textAlign: "center" }}>
                    Meet our Team
                </Title>
                <Text style={{ textAlign: "center",paddingLeft: getPadding(), paddingRight: getPadding()}}>
                    Meet the minds behind Unizo—our founders, leadership team, trusted advisors and a team of professionals who bring years of experience in SaaS, integrations, DevOps, and cybersecurity. Behind Unizo is a growing team of talented engineers, product experts, and customer success professionals who share a passion for solving integration challenges. Our team members bring expertise across SaaS, DevOps, cybersecurity, API, AI, and cloud infrastructure, ensuring that we deliver the best possible solutions to our customers.
                </Text>
            </div>

            <Row gutter={[16, 16]} justify="center">
                {teamMembers.map((member, index) => (
                    <Col xs={24} sm={24} lg={12} key={member.name}>
                        <div
                            onClick={() => showModal(member)}
                            style={{
                                display: "flex",
                                flexDirection: screens.xs ? "column" : "row",
                                alignItems: screens.xs?"center":"start",
                                background: "#f9f9f9",
                                padding: "16px",
                                borderRadius: "8px",
                                cursor: "pointer",
                                gap: "1rem",
                                transition: "all 0.3s",
                            }}
                        >
                            <img
                                src={member.image}
                                alt={member.name}
                                style={{
                                    width: "130px",
                                    height: "130px",
                                    borderRadius: "50%",
                                    objectFit: "cover",
                                    marginRight: index % 2 === 0 ? "16px" : "0",
                                    marginLeft: index % 2 !== 0 ? "16px" : "0",
                                }}
                            />
                            <div style={{ textAlign: screens.xs ?"center":"left", display: "flex", flexDirection: "column", gap: "0px" }}>
                                <Title level={4} style={{  textAlign: screens.xs ?"center":"left", margin: 0 }}>
                                    {member.name}
                                </Title>
                                <h4 style={{ marginBottom: "1rem" }}>{member.title}</h4>
                                <p style={{ margin: 0 }}>{member.shortDescription}</p>
                                <p style={{ margin: 0, color: "blue", cursor: "pointer" }}>Read more</p>
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>

            {selectedMember && (
                <Modal
                    title={selectedMember.name}
                    open={visible}
                    onCancel={handleClose}
                    footer={null}
                    style={{ minWidth: "800px" }}
                >
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "2rem" }}>
                        <img
                            src={selectedMember.image}
                            alt={selectedMember.name}
                            style={{
                                width: "120px",
                                borderRadius: "50%",
                                display: "block",
                                margin: "0 auto 10px",
                                height: "120px"
                            }}
                        />
                        <div>
                            <Title level={2} style={{ margin: 0 }} >
                                {selectedMember.name}
                            </Title>
                            <Title level={4}style={{ margin: 0 }} >
                                {selectedMember.title}
                            </Title>
                            <p style={{ margin: 0 }}>
                                {selectedMember.fullDescription}
                            </p>
                        </div>
                    </div>

                </Modal>
            )}
        </div>
    );
};
